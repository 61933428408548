<template>
    <div class="empty-layout">
        <div class="container">
            <div class="items">
                <img
                    src="/images/icon-lansete.svg"
                    alt="icon-lansete"
                >
                <img
                    src="/images/icon-mulinete.svg"
                    alt="icon-mulinete"
                >
                <img
                    src="/images/icon-accesorii-nadire-momitoare.svg"
                    alt="icon-accesorii-nadire-momitoare"
                >
                <img
                    src="/images/icon-accesorii-pescuit.svg"
                    alt="icon-accesorii-pescuit"
                >
                <img
                    src="/images/icon-avertizoare-si-suporti.svg"
                    alt="icon-avertizoare-si-suporti"
                >
            </div>
            <div class="items">
                <img
                    src="/images/icon-bagajerie.svg"
                    alt="icon-bagajerie"
                >
                <img
                    src="/images/icon-camping.svg"
                    alt="icon-camping"
                >
            </div>
            <div class="items">
                <img
                    src="/images/icon-carlige-plute.svg"
                    alt="icon-carlige-plute"
                >
                <img
                    src="/images/icon-fire.svg"
                    alt="icon-fire"
                >
                <img
                    src="/images/icon-imbracaminte-incaltaminte.svg"
                    alt="icon-imbracaminte-incaltaminte"
                >
                <img
                    src="/images/icon-nade-momeli.svg"
                    alt="icon-nade-momeli"
                >
                <img
                    src="/images/icon-naluci.svg"
                    alt="icon-naluci"
                >
                <img
                    src="/images/icon-protectie-pastrare.svg"
                    alt="icon-protectie-pastrare"
                >
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'EmptyCartBackground',
    };
</script>
<style scoped lang="scss">
.empty-layout {
  position: absolute;
  height: calc(100% + 25px);
  width: 100%;
  top: 60px;
  @include media-breakpoint-down(md) {
    height: calc(100% + 25px - 80px);
    top: 100px;
  }
  @include media-breakpoint-down(sm) {
    height: calc(100% + 25px - 120px);
    top: 120px;
  }
  .container {
    flex-wrap: wrap;
    height: 100%;
    .items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 33.33%;
      &:first-of-type,
      &:last-of-type {
        img {
          @include media-breakpoint-down(sm) {
            &:last-of-type {
              display: none;
            }
          }
        }
      }
      img {
        width: 20%;
        height: 45%;
        opacity: 0.04;
        @include media-breakpoint-down(sm) {
          height: 25%;
        }
        &:nth-of-type(n + 1) {
          margin-top: 40px;
          width: 25%;
          height: 35%;
          opacity: 0.06;
           @include media-breakpoint-down(sm) {
          margin-top: 80px;
        }
        }
        &:nth-of-type(2n + 1) {
          margin-top: -40px;
          height: 35%;
          opacity: 0.04;
          @include media-breakpoint-down(sm) {
            height: 25%;
             margin-top: -80px;
          }
        }
        &:nth-of-type(3n + 1) {
          height: 55%;
          width: 30%;
          opacity: 0.05;
          @include media-breakpoint-down(sm) {
            height: 30%;
          }
        }
      }
    }
  }
}
</style>
