<template>
    <b-col
        cols="12"
        md="8"
        class="cart-items"
    >
        <div class="cart-heading">
            <div class="details">
                <p class="sm-sbold">
                    {{ $t('page.cart.detailsText') }}
                </p>
            </div>
            <div class="quantity">
                <p class="sm-sbold">
                    {{ $t('page.cart.quantityText') }}
                </p>
            </div>
            <div class="prices">
                <p class="sm-sbold">
                    {{ $t('page.cart.priceText') }}
                </p>
            </div>
            <div class="close" />
        </div>
        <div class="cart-content">
            <div
                v-for="product in products"
                :key="`cart-item-product-${product.code}`"
                class="item"
            >
                <div class="details">
                    <router-link :to="{ name: 'Produs', params: { slug: product.slug } }">
                        <div class="image">
                            <img
                                :src="product.thumbnail"
                                :alt="product.name"
                            >
                        </div>
                    </router-link>
                    <div class="right-details">
                        <router-link
                            :to="{ name: 'Produs', params: { slug: product.slug } }"
                            class="title"
                        >
                            <p class="sbold">
                                {{ product.name }}
                            </p>
                        </router-link>
                        <div class="prices">
                            <span
                                class="price"
                            ><del
                                 v-if="product.discount_percentage"
                                 v-dompurify-html="formatPrice(product.price)"
                             />
                                <p
                                    v-if="!product.discount_percentage"
                                    v-dompurify-html="formatPrice(product.current_price)"
                                    class="has-sup sbold"
                                />
                                <p
                                    v-else
                                    v-dompurify-html="formatPrice(product.current_price)"
                                    class="has-sup sbold"
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="quantity">
                    <div class="quantity-content">
                        <b-button
                            variant="link"
                            class="text"
                            @click="changeQuantity(product.id, product.quantity, 'decr')"
                        >
                            <minus-icon />
                        </b-button>
                        <input
                            type="number"
                            name="quantity"
                            :value="product.quantity"
                            min="0"
                            max="99"
                            @keyup.enter="
                                changeQuantity(product.id, $event.target.value, 'set')
                            "
                            @change="changeQuantity(product.id, $event.target.value, 'set')"
                        >
                        <b-button
                            variant="link"
                            class="text"
                            @click="changeQuantity(product.id, product.quantity, 'incr')"
                        >
                            <plus-icon />
                        </b-button>
                    </div>
                </div>
                <div class="prices">
                    <span
                        class="price"
                    ><del
                         v-if="product.discount_percentage"
                         v-dompurify-html="formatPrice(product.price)"
                     />
                        <p
                            v-if="!product.discount_percentage"
                            v-dompurify-html="formatPrice(product.current_price)"
                            class="has-sup sbold"
                        />
                        <p
                            v-else
                            v-dompurify-html="formatPrice(product.current_price)"
                            class="has-sup sbold"
                        />
                    </span>
                </div>
                <div class="close">
                    <b-button variant="icon">
                        <x-icon @click="removeFromCart(product.id)" />
                    </b-button>
                </div>
            </div>
            <div
                v-for="voucher in vouchers"
                :key="`cart-item-voucher-${voucher.value}`"
                class="item"
            >
                <div class="details">
                    <div class="image voucher">
                        <router-link :to="{ name: 'Voucher' }">
                            <img
                                :src="voucher.thumbnail"
                                :alt="voucher.name"
                            >
                        </router-link>
                    </div>
                    <div class="right-details">
                        <router-link
                            :to="{ name: 'Voucher' }"
                            class="title"
                        >
                            <p class="sbold">
                                {{ voucher.name }}
                            </p>
                        </router-link>
                        <div class="prices">
                            <span class="price">
                                <p
                                    v-dompurify-html="formatPrice(voucher.value)"
                                    class="has-sup sbold"
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="quantity">
                    <div class="quantity-content">
                        <b-button
                            variant="link"
                            class="text"
                            @click="
                                changeQuantity(voucher.value, voucher.quantity, 'decr', true)
                            "
                        >
                            <minus-icon />
                        </b-button>
                        <input
                            type="number"
                            name="quantity"
                            :value="voucher.quantity"
                            min="0"
                            max="99"
                            @keyup.enter="
                                changeQuantity(voucher.value, $event.target.value, 'set', true)
                            "
                            @change="
                                changeQuantity(voucher.value, $event.target.value, 'set', true)
                            "
                        >
                        <b-button
                            variant="link"
                            class="text"
                            @click="
                                changeQuantity(voucher.value, voucher.quantity, 'incr', true)
                            "
                        >
                            <plus-icon />
                        </b-button>
                    </div>
                </div>
                <div class="prices">
                    <span class="price">
                        <p
                            v-dompurify-html="formatPrice(voucher.value)"
                            class="has-sup sbold"
                        />
                    </span>
                </div>
                <div class="close">
                    <b-button variant="icon">
                        <x-icon @click="removeFromCart(voucher.value, true)" />
                    </b-button>
                </div>
            </div>
        </div>
    </b-col>
</template>

<script>
    import MinusIcon from 'vue-feather-icons/icons/MinusIcon';
    import PlusIcon from 'vue-feather-icons/icons/PlusIcon';
    import XIcon from 'vue-feather-icons/icons/XIcon';
    import { mapActions, mapState } from 'vuex';

    import { splitedPrice } from '@/services/getSplitedPrice';

    export default {
        name: 'CartDetails',
        components: {
            MinusIcon,
            PlusIcon,
            XIcon,
        },
        computed: {
            ...mapState('cart', ['products', 'vouchers', 'loading']),
        },
        methods: {
            ...mapActions('cart', ['changeItemQuantity', 'removeItem']),
            changeQuantity(value, quantity, type, isVoucher = false) {
                switch (type) {
                case 'decr':
                    if (quantity > 1) {
                        this.changeItemQuantity({
                            value: value,
                            quantity: --quantity,
                            isVoucher: isVoucher,
                        });
                    } else {
                        this.$toast.open({
                            message: this.$t('notification.minQuantityAlert', {
                                quantity: 1,
                            }),
                            duration: 4000,
                            position: this.$screen.width < 768 ? 'bottom-right' : 'top-right',
                            type: 'info',
                        });
                    }
                    break;
                case 'incr':
                    if (quantity < 99) {
                        this.changeItemQuantity({
                            value: value,
                            quantity: ++quantity,
                            isVoucher: isVoucher,
                        });
                    } else {
                        this.$toast.open({
                            message: this.$t('notification.maxQuantityAlert', {
                                quantity: 99,
                            }),
                            duration: 4000,
                            position: this.$screen.width < 768 ? 'bottom-right' : 'top-right',
                            type: 'info',
                        });
                    }
                    break;
                case 'set':
                    if (quantity >= 1 && quantity <= 99) {
                        this.changeItemQuantity({
                            value: value,
                            quantity: quantity,
                            isVoucher: isVoucher,
                        });
                    } else {
                        this.$toast.open({
                            message: this.$t('notification.minMaxQuantityAlert', {
                                min: 1,
                                max: 99,
                            }),
                            duration: 4000,
                            position: 'top-right',
                            type: 'info',
                        });
                    }
                    break;
                default:
                    break;
                }
            },
            removeFromCart(value, isVoucher = false) {
                this.removeItem({ itemId: value, isVoucher: isVoucher });
            },

            formatPrice(price) {
                return splitedPrice(price);
            },
        },
    };
</script>

<style scoped lang="scss">
.cart-items {
  .cart-heading {
    gap: 12px;
    display: flex;
    padding-bottom: 12px;
    border-bottom: 1px solid $gray-thin;
    @include media-breakpoint-down(lg) {
      display: none;
    }
    p {
      color: $text;
      text-transform: uppercase;
      text-align: center;
    }
    .prices {
      height: auto;
    }
  }
  .cart-content {
    @include media-breakpoint-down(md) {
      margin-top: 12px;
    }
    .item {
      position: relative;
      gap: 12px;
      padding: 20px 0px;
      display: flex;
      border-bottom: 1px solid $gray-thin;
      &:last-of-type {
        border: none;
      }
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        &.details {
          gap: 24px;
          justify-content: flex-start;
          @include media-breakpoint-down(xm) {
            align-items: flex-start;
            gap: 12px;
          }
          .image {
            height: 100px;
            width: 150px;
            background-color: $gray-thin;
            border-radius: 4px;
            flex: none;
            @include media-breakpoint-between(xm, lg) {
              height: 75px;
              width: 110px;
            }
            @include media-breakpoint-down(xm) {
              height: 40px;
              width: 60px;
            }
            img {
              object-fit: contain;
            }
            p {
              color: $text;
            }
          }
          .right-details {
            .prices {
              display: none;
              @include media-breakpoint-down(md) {
                display: block;
                margin-top: 8px;
                width: 100%;
                .price {
                  gap: 8px;
                  flex-direction: row;
                  justify-content: start;
                  height: initial;
                  .big {
                    padding-top: 2px;
                  }
                  p {
                    color: $primary;
                  }
                }
              }
              @include media-breakpoint-down(xm) {
                margin-top: 2px;
              }
            }

            @include media-breakpoint-down(xm) {
              .prices {
                .price {
                  flex-direction: column;
                  gap: 0px;
                }
              }
            }
          }
        }
        &.quantity {
          @include media-breakpoint-down(md) {
            align-items: flex-end;
            justify-content: flex-end;
          }
          .quantity-content {
            display: flex;
            align-items: center;
            gap: 4px;
            input[type='number'] {
              border: 1px solid $primary-25;
              height: 32px;
              width: 32px;
              text-align: center;
              font-size: 14px;
              color: $text;
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
              -moz-appearance: textfield;
              appearance: textfield;
              @include media-breakpoint-down(xm) {
                height: 24px;
                width: 24px;
                font-size: 12px;
              }
            }
            .btn {
              width: 26px;
              height: 26px;
              margin: 0;
              padding: 0;
              @include media-breakpoint-down(xm) {
                width: 24px;
                height: 24px;
              }
              svg {
                margin: 0;
              }
            }
          }
        }
        &.prices {
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
        &.price {
          height: auto;
          p {
            color: $text;
          }
        }
      }
    }
  }

  .details {
    width: 60%;
    @include media-breakpoint-between(xm, md) {
      width: 80%;
    }
    @include media-breakpoint-down(xm) {
      width: 100%;
      padding-right: 3em;
    }
  }
  .quantity {
    width: 15%;
    @include media-breakpoint-between(xm, md) {
      width: 20%;
    }
    @include media-breakpoint-down(xm) {
      width: fit-content;
      position: absolute;
      right: 0;
      bottom: 20px;
    }
  }
  .prices {
    width: 15%;
    .price {
      justify-content: center;
      p {
        color: $text;
      }
    }
  }
  .close {
    width: 10%;
    @include media-breakpoint-down(md) {
      position: absolute;
      right: 0;
      top: 15px;
      width: 24px;
    }
    @include media-breakpoint-down(xm) {
      top: 18px;
    }
    .btn {
      padding: 0;
      margin: 0;
      text-align: center;
      width: 20px;
      height: 20px;
      svg {
        margin: 0;
      }
    }
  }
}
</style>
