<template>
    <div
        class="container cart-page"
        :class="{ isEmpty: isEmpty }"
    >
        <loading-spinner
            v-if="loading"
            class="fixed"
        />
        <empty-cart-background v-if="isEmpty" />
        <b-row
            v-if="!isEmpty"
            class="cart-wrapper"
            :class="{ transparent: loading }"
        >
            <b-col
                cols="12"
                class="page-header"
            >
                <h4>{{ $t('page.cart.heading') }}</h4>
            </b-col>
            <cart-details />
            <b-col
                cols="12"
                md="4"
            >
                <cart-summary
                    :coupon-code="appliedCoupon"
                    action-name="Checkout"
                    :class="{ sticky: sticky }"
                />
            </b-col>
            <cart-info />
        </b-row>
        <b-row
            v-if="canLoadEmptyContent"
            class="mt-40 cart-wrapper"
        >
            <div class="icon">
                <shopping-cart-icon />
            </div>
            <p class="sm-sbold">
                {{ settings.cart_title }}
            </p>
            <h6 class="mt-4">
                {{ settings.cart_description }}
            </h6>
            <span>
                <router-link
                    v-slot="{ navigate }"
                    :to="{ path: settings.cart_button_link }"
                    custom
                >
                    <b-button
                        variant="primary"
                        class="sm"
                        @click="navigate"
                    >
                        <span>{{ settings.cart_button_label }}</span>
                    </b-button>
                </router-link>
            </span>
        </b-row>
    </div>
</template>

<script>
    import ShoppingCartIcon from 'vue-feather-icons/icons/ShoppingCartIcon';
    import { i18n } from 'vue-lang-router';
    import { mapActions, mapState } from 'vuex';

    import EmptyCartBackground from '../components/controls/page/EmptyCartBackground';
    import LoadingSpinner from '../components/loaders/LoadingSpinner';
    import CartDetails from './cart/CartDetails';
    import CartInfo from './cart/CartInfo';
    import CartSummary from './cart/CartSummary';
    export default {
        components: {
            CartDetails,
            CartSummary,
            CartInfo,
            ShoppingCartIcon,
            EmptyCartBackground,
            LoadingSpinner,
        },
        name: 'Cart',
        data() {
            return {
                sticky: false,
                screenWidth: 0,
            };
        },
        computed: {
            ...mapState('cart', ['products', 'vouchers', 'loading', 'settings', 'appliedCoupon', 'total']),
            lang() {
                return i18n.locale;
            },
            isEmpty() {
                return (
                    Object.keys(this.products).length +
                    Object.keys(this.vouchers).length ===
                    0
                );
            },
            canLoadEmptyContent() {
                return (
                    this.isEmpty && !this.loading && Object.keys(this.settings).length > 0
                );
            },
        },
        methods: {
            ...mapActions('cart', ['getItems']),
            initialize() {
                this.getItems();
            },
            handleScroll() {
                if (window.scrollY > 85 && !this.sticky) {
                    this.sticky = true;
                } else if (window.scrollY <= 85 && this.sticky) {
                    this.sticky = false;
                }
            },

            screenSizeChanged() {
                this.screenWidth = window.innerWidth;
            },

            reInitializeScrollListener() {
                window.removeEventListener('scroll', this.handleScroll);
                this.sticky = false;
                if (this.screenWidth >= 768) {
                    window.addEventListener('scroll', this.handleScroll);
                    if (window.scrollY > 85) {
                        this.sticky = true;
                    }
                }
            },
        },
        mounted() {
            window.addEventListener('resize', this.screenSizeChanged);
            this.screenWidth = window.innerWidth;
        },
        unmounted() {
            window.removeEventListener('resize', this.screenSizeChanged);
        },
        created() {
            this.initialize();
            if (this.$screen.width >= 768) {
                window.addEventListener('scroll', this.handleScroll);
            }
        },
        destroyed() {
            if (this.$screen.width >= 768) {
                window.removeEventListener('scroll', this.handleScroll);
            }
        },
        watch: {
            appliedCoupon: {
                handler(couponCode) {
                    if (couponCode && !couponCode.valid) {
                        this.$toast.open({
                            message: this.$t('notification.promoCodeRemoved', {
                                name: couponCode.name,
                            }),
                            duration: 10000,
                            position: 'bottom-right',
                            type: 'info',
                        });
                    }
                },
                deep: true,
            },
            lang: {
                handler() {
                    this.initialize();
                },
                deep: true,
            },
            screenWidth: {
                handler() {
                    this.reInitializeScrollListener();
                },
                deep: true,
            },
        },
        metaInfo() {
            return {
                title: this.$t('meta.cart.title'),
                meta: [
                    {
                        name: 'robots',
                        content: 'noodp',
                    },
                    {
                        vmid: 'description',
                        name: 'description',
                        content: '',
                    },
                    {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: '',
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: '',
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: '',
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: '',
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: '',
                    },
                ],
                link: [
                    {
                        rel: 'canonical',
                        href: '',
                    },
                ],
            };
        },
    };
</script>
<style scoped lang="scss">
.cart-page {
  min-height: calc(100vh - 450px);
  &.isEmpty {
    display: flex;
    align-items: center;
    justify-content: center;
    .cart-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
      * {
        text-align: center;
        width: auto;
      }
      .icon {
        svg {
          height: 50px;
          width: 50px;
          stroke: $primary-50;
        }
      }
      p {
        margin-top: 16px;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: $gray;
      }
      .btn {
        margin-top: 16px;
      }
    }
  }
  .summary {
    &.sticky {
      position: -webkit-sticky;
      position: sticky;
      top: 90px;
    }
    @include media-breakpoint-down(md) {
      padding-top: 24px;
    }
  }
}
</style>
